@tailwind base;

@layer base {
    @font-face {
        src: url('../public/fonts/forma/FormaDJRCyrillicDisplay-Medium.woff2') format("woff2"),
             url('../public/fonts/forma/FormaDJRCyrillicDisplay-Medium.woff') format("woff");
        font-family: 'FormaDJRCyrillicDisplay';
        font-style: normal;
        font-weight: 500;
    }

    @font-face {
        src: url('../public/fonts/forma/FormaDJRCyrillicDisplay-Light.woff2') format("woff2"),
             url('../public/fonts/forma/FormaDJRCyrillicDisplay-Light.woff') format("woff");
        font-family: 'FormaDJRCyrillicDisplay';
        font-style: normal;
        font-weight: 300;
    }

    @font-face {
        src: url('../public/fonts/forma/FormaDJRCyrillicDisplay-Regular.woff2') format("woff2"),
             url('../public/fonts/forma/FormaDJRCyrillicDisplay-Regular.woff') format("woff");
        font-family: 'FormaDJRCyrillicDisplay';
        font-style: normal;
        font-weight: normal;
    }

    html, body {
        @apply font-normal leading-tight sm:scroll-smooth;
    }

    html.locked, html.locked body {
        @apply sm:h-auto overflow-hidden relative;
    }

    button[disabled] {
        @apply cursor-default opacity-50;
    }

    *::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }
    /*
    *::-webkit-scrollbar-thumb {
        border-radius: 8px;
    }
    */
    html.scrollbar-dark *::-webkit-scrollbar-track {
        background-color: theme('colors.dark');
    }
    html.scrollbar-dark *::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.5);
    }
    html.scrollbar-light *::-webkit-scrollbar-track {
        background-color: theme('colors.light');
    }
    html.scrollbar-light *::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
    }

    b {
        @apply font-medium;
    }
}


@tailwind components;

@layer components {

    .text-curved {
        @apply font-playfair font-medium italic;
    }
    .text-gradient {
        @apply bg-gradient-main;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .bg-gradient-main {
        background: linear-gradient(308.42deg, #E5EEC1 1.29%, #A2D5AC 36.44%, #3AADA8 69.41%, #557C83 100.2%), #D9D9D9;
    }

    .main-title {
        @apply sm:text-[10vw] text-[12vw] font-medium text-center leading-[82%];
    }

    .main-button {
        @apply
            sm:h-vw-14 h-[9vw]
            sm:text-vw-3xl text-vw-sm
            bg-gradient-main sm:rounded-[0.75vw] rounded-vw-xl
            relative
            sm:px-vw-18 px-vw-36;
    }
    .main-button::before {
        content: '';
        @apply
            sm:w-[115%] w-[130%] sm:h-[140%] h-[150%]
            bg-gradient-main rounded-[100%] sm:blur-xl blur-md
            absolute top-1/2 left-1/2 z-[-1]
            -translate-y-1/2 -translate-x-1/2;
    }

    .main-border-button {
        @apply sm:h-vw-14 h-[9vw] sm:text-vw-2xl text-vw-sm relative z-10 sm:px-vw-20 px-vw-28;
    }
    .main-border-button::before {
        content: '';
        @apply bg-gradient-main sm:rounded-[0.75vw] rounded-vw-xl absolute inset-0;
        z-index: -2;
    }
    .main-border-button::after {
        content: '';
        @apply bg-dark sm:rounded-[calc(0.75vw-0.35vw+2px)] rounded-[calc(1.3vw-1px)] absolute sm:inset-[0.25vw] inset-px transition-all;
        z-index: -1;
    }
    .main-border-button:hover::after {
        @apply sm:inset-[0.35vw] inset-[3px] sm:rounded-[0.4vw] rounded-[calc(1.3vw-3px)];
    }

    .main-button[disabled], .main-border-button[disabled] {
        @apply pointer-events-none opacity-50;
    }

    .main-input {
        @apply
            sm:w-vw-64 sm:h-vw-14 w-full h-[9vw]
            text-curved sm:text-vw-3xl text-vw-sm text-dark placeholder:text-dark
            focus-visible:outline focus-visible:outline-2 focus-visible:outline-white/60
            bg-gradient-main rounded-vw-xl sm:px-vw-6 px-vw-11;
    }

    .main-underline {
        @apply relative;
    }
    .main-underline::after {
        content: '';
        @apply h-0.5 bg-gradient-main absolute inset-x-0 bottom-vw-1 opacity-0 transition-all;
    }
    .main-underline-hover::after {
        @apply opacity-100 bottom-0;
    }

    .main-link {
        @apply cursor-pointer main-underline hover:main-underline-hover;
    }

    .blurred-circle {
        @apply bg-gradient-main rounded-full blur-3xl;
    }

    .next-img > span, .next-img > span > img {
        width: 100% !important;
        height: unset !important;
        position: unset !important;
    }
    .next-img-full > span, .next-img-full > span > img {
        width: 100% !important;
        height: 100% !important;
        position: unset !important;
    }
    .next-img-h > span, .next-img-h > span > img {
        position: relative !important;
        inset: unset !important;
        height: 100% !important;
        width: auto !important;
    }

    .richtext {
        @apply sm:text-vw-2xl text-vw-lg leading-normal tracking-[0.045vw];
    }
    .richtext *:empty {
        @apply inline-block;
    }

    .project-details-richtext h3 {
        @apply sm:text-vw-xl text-vw-base tracking-[0.1vw] uppercase mt-vw-10;
    }
    .project-details-richtext h3:first-of-type {
        @apply mt-0;
    }
    .project-details-richtext p {
        @apply sm:text-vw-xl text-vw-base font-light tracking-[0.1vw] mt-vw-4
    }

    .brief-step-text-container > * {
        @apply align-middle;
    }
    .brief-step-text i {
        @apply text-curved;
    }

    .brief-steps-container > * {
        @apply align-top;
    }

    .brief-input-container {
        @apply flex flex-col w-full;
    }
    .brief-input-container .label, .brief-input-container .help-text {
        @apply sm:text-vw-xl text-vw-sm sm:leading-[85%] tracking-[0.045vw] sm:ml-vw-5 ml-vw-20;
    }
    .brief-input-container .super-label h3 {
        @apply text-curved sm:text-vw-5xl text-vw-4xl text-[#A4A4A4] leading-[85%] ml-vw-7;
    }
    .brief-input-container input,
    .brief-input-container textarea,
    .brief-input-container select,
    .brief-input-container .fake-input,
    .brief-input-container .preview-textarea,
    .brief-input-container .preview-input {
        @apply bg-[#D9D9D980]/50 rounded-vw-2xl;
    }
    .brief-input-container input[type="text"],
    .brief-input-container input[type="email"],
    .brief-input-container input[type="tel"],
    .brief-input-container textarea,
    .brief-input-container .fake-input,
    .brief-input-container .preview-input,
    .brief-input-container .preview-textarea {
        @apply w-full sm:h-vw-14 h-vw-48 sm:text-vw-3xl text-vw-xl tracking-[0.045vw] sm:pt-0 pt-vw-1 sm:px-vw-5 px-vw-14;
    }
    .brief-input-container textarea,
    .brief-input-container .preview-input,
    .brief-input-container .preview-textarea {
        @apply h-auto sm:min-h-[4.375vw] min-h-[15vw] sm:py-[1.2vw] py-[4.7vw];
    }

    .brief-checkbox-border {
        @apply relative cursor-pointer sm:rounded-vw-lg rounded-vw-2xl;
    }
    .brief-checkbox-border::before {
        content: '';
        @apply bg-gradient-main absolute -inset-[4px] sm:rounded-[calc(0.45vw+4px)] rounded-[calc(2vw+4px)] transition-opacity;
        z-index: -2;
    }
    .brief-checkbox-border::after {
        content: '';
        @apply bg-light absolute -inset-[2px] sm:rounded-[calc(0.45vw+2px)] rounded-[calc(2vw+2px)];
        z-index: -1;
    }

    .brief-checkbox {
        @apply sm:w-[2.78vw] w-vw-28 sm:h-[2.78vw] h-vw-28 bg-[#D9D9D980]/50 sm:rounded-vw-lg rounded-vw-2xl cursor-pointer relative;
    }
    .brief-checkbox.with-border {
        @apply bg-gradient-main;
    }
    .brief-checkbox::before {
        content: '';
        @apply absolute inset-0 sm:rounded-[calc(0.45vw-2px)] rounded-[calc(2vw-2px)];
        z-index: -1;
    }
    .brief-checkbox.with-border::before {
        @apply bg-light inset-[2px];
    }
    .brief-checkbox::after {
        content: '';
        @apply bg-gradient-main absolute inset-[6px] sm:rounded-[calc(0.45vw-2px)] rounded-[calc(2vw-3px)] transition-opacity;
        z-index: 2;
    }
}


@tailwind utilities;

@layer utilities {

    .text-vw-xs {
        font-size: 0.625vw;
    }
    .text-vw-sm {
        font-size: 0.73vw;
    }
    .text-vw-base {
        font-size: 0.834vw;
    }
    .text-vw-lg {
        font-size: 0.938vw;
    }
    .text-vw-xl {
        font-size: 1.042vw;
    }
    .text-vw-2xl {
        font-size: 1.25vw;
    }
    .text-vw-3xl {
        font-size: 1.563vw;
    }
    .text-vw-4xl {
        font-size: 1.875vw;
    }
    .text-vw-5xl {
        font-size: 2.5vw;
    }
    .text-vw-6xl {
        font-size: 3.125vw;
    }
    .text-vw-7xl {
        font-size: 3.75vw;
    }
    .text-vw-8xl {
        font-size: 5vw;
    }
    .text-vw-9xl {
        font-size: 6.667vw;
    }

    @media (max-width: 639.99px) {
        .text-vw-xxs {
            font-size: 2.43vw;
        }
        .text-vw-xs {
            font-size: 2.9vw;
        }
        .text-vw-sm {
            font-size: 3.37vw;
        }
        .text-vw-base {
            font-size: 3.87vw;
        }
        .text-vw-lg {
            font-size: 4.57vw;
        }
        .text-vw-xl {
            font-size: 4.83vw;
        }
        .text-vw-2xl {
            font-size: 6.1vw;
        }
        .text-vw-3xl {
            font-size: 7.25vw;
        }
        .text-vw-4xl {
            font-size: 8.66vw;
        }
        .text-vw-5xl {
            font-size: 11.6vw;
        }
        .text-vw-6xl {
            font-size: 14.5vw;
        }
        .text-vw-7xl {
            font-size: 17.4vw;
        }
    }

    .rounded-vw-lg {
        border-radius: 0.45vw;
    }
    .rounded-vw-xl {
        border-radius: 0.6vw;
    }
    .rounded-vw-2xl {
        border-radius: 0.75vw;
    }
    .rounded-t-vw-xl {
        border-radius: 0.6vw 0.6vw 0vw 0vw;
    }
    .rounded-b-vw-xl {
        border-radius: 0vw 0vw 0.6vw 0.6vw;
    }
    .rounded-vw-3xl {
        border-radius: 1.25vw;
    }

    @media (max-width: 639.99px) {
        .rounded-vw-xl {
            border-radius: 1.3vw;
        }
        .rounded-vw-2xl {
            border-radius: 2vw;
        }
        .rounded-l-vw-xl {
            border-radius: 1.3vw 0vw 0vw 1.3vw;
        }
        .rounded-r-vw-xl {
            border-radius: 0vw 1.3vw 1.3vw 0vw;
        }
    }

    .webkit-overflow-touch {
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
    }
}